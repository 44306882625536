import {
  FileFragmentData,
  FileSubmissionFragmentData,
  IFileNodeFragmentData,
} from '@app-features/file-tree/types';
import {JobFragmentData} from '@app-features/jobs/types';
import {MilestoneFragmentData} from '@app-features/milestones/types';
import {AvailableFileUpload} from '@app-lib/apollo/localTypes';
import {LocalFile} from '@app-system/camera/types';
import {createAction} from 'redux-starter-kit';


export interface DownloadFilePayload {
  file:
    | Pick<FileFragmentData, "__typename" | "id" | "name">
    | Pick<FileSubmissionFragmentData, "__typename" | "id" | "name">
}

export interface DownloadFileListPayload {
  mutationId?: string;
  /** Local files to download */
  uploads: Array<AvailableFileUpload>;
  /** Remote files to download */
  files: Array<Pick<MilestoneFragmentData | IFileNodeFragmentData, "__typename" | "id" | "name">
    | Pick<JobFragmentData, "__typename" | "id" | "name" | "folderId">>;
  relativeTo?: string | null;
  excludePath?: boolean | null;
}

export type DownloadFileListTransformPayload =
  & (DownloadFileListNoResizePayload | DownloadFileListResizePayload)

export interface DownloadFileListNoResizePayload extends DownloadFileListPayload {
  useResize?: false;
  resizeMaxSize?: number;
}

export interface DownloadFileListResizePayload extends DownloadFileListPayload {
  useResize: true;
  resizeMaxSize: number;
}

export interface DownloadLocalFilePayload {
  file: File | LocalFile;
}

export type FileNodeDownloadActions =
  | ReturnType<typeof downloadFile>
  | ReturnType<typeof downloadFileList>
  | ReturnType<typeof downloadFileListTransform>
  | ReturnType<typeof downloadLocalFile>

export const downloadFile = createAction<DownloadFilePayload, "downloads/downloadFile">("downloads/downloadFile");
export const downloadFileList = createAction<DownloadFileListPayload, "downloads/downloadFileList">("downloads/downloadFileList");
export const downloadFileListTransform = createAction<DownloadFileListTransformPayload, "downloads/downloadFileListTransform">("downloads/downloadFileListTransform");
export const downloadLocalFile = createAction<DownloadLocalFilePayload, "downloads/downloadLocalFile">("downloads/downloadLocalFile");
