import {isString} from 'lodash';
import { JsonType } from '../JsonType';
export class JsonDragSource {
  constructor() {
    this.type = JsonType;
    this.item = {};
  }
  mutateItemByReadingDataTransfer(dataTransfer) {
    if (dataTransfer) {
      const json = dataTransfer.getData(JsonType);
      try {
        if (isString(json)) {
          Object.assign(this.item, JSON.parse(json));
        }
      } catch (e) {
      }
    }
  }
  canDrag() {
    return true;
  }
  beginDrag() {
    return this.item;
  }
  isDragging(monitor, handle) {
    return handle === monitor.getSourceId();
  }
  endDrag() {
    // empty
  }
}
