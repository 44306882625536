import {FileContainerFragmentData} from '@app-features/file-tree/types';
import {FileNodeStatus} from '@app-lib/apollo/apiTypes';
import {createAction} from 'redux-starter-kit';


export interface UpdateFileContainerStatusBatchPayload {
  models: Array<Pick<FileContainerFragmentData, "__typename" | "id" | "name">>
  status: FileNodeStatus
  skipApproved?: boolean
}

export type FileNodeStatusUpdateActions =
  | ReturnType<typeof updateFileContainerStatusBatch>

export const updateFileContainerStatusBatch = createAction<UpdateFileContainerStatusBatchPayload, "files/updateFileContainerStatusBatch">("files/updateFileContainerStatusBatch");
