import {createAction} from 'redux-starter-kit';
import {UrlObject} from 'url';


export type OpenPagePayload =
  | OpenPageDefaultPayload
  | OpenPageNewTabPayload

export interface OpenPageDefaultPayload {
  newTab?: false
  url: UrlObject | string
  as?: UrlObject | string
  // TODO: this isn't typed by current version of NextJS. Document this.
  options?: {
    shallow?: boolean
  }
}

export interface OpenPageNewTabPayload {
  newTab: true
  url: string
}

export type RouterActions =
  | ReturnType<typeof openPage>

export const openPage = createAction<OpenPagePayload, "router/openPage">("router/openPage");
