import {createAction} from 'redux-starter-kit';
import {LocalFile} from '../types';


export interface OpenCameraPayload {
  onAcceptPhoto?: ((file?: File | LocalFile | null) => void) | null;
}

export type CameraActions =
  | ReturnType<typeof openCamera>
  | ReturnType<typeof closeCamera>

export const openCamera = createAction<OpenCameraPayload, "camera/openCamera">("camera/openCamera");
export const closeCamera = createAction<void, "camera/closeCamera">("camera/closeCamera");
