import {IFileNodeFragmentData} from '@app-features/file-tree/types';
import {createAction} from 'redux-starter-kit';


export interface LockFileNodePayload {
  model: Pick<IFileNodeFragmentData, "__typename" | "id" | "name">;
}

export interface UnlockFileNodePayload {
  model: Pick<IFileNodeFragmentData, "__typename" | "id" | "name">;
}

export interface LockFileNodeBatchPayload {
  models: Array<Pick<IFileNodeFragmentData, "__typename" | "id" | "name">>;
}

export interface UnlockFileNodeBatchPayload {
  models: Array<Pick<IFileNodeFragmentData, "__typename" | "id" | "name">>;
}

export type FileNodeLocksActions =
  | ReturnType<typeof lockFileNode>
  | ReturnType<typeof unlockFileNode>
  | ReturnType<typeof lockFileNodeBatch>
  | ReturnType<typeof unlockFileNodeBatch>

export const lockFileNode = createAction<LockFileNodePayload, "file-locks/lockFileNode">("file-locks/lockFileNode");
export const unlockFileNode = createAction<UnlockFileNodePayload, "file-locks/unlockFileNode">("file-locks/unlockFileNode");
export const lockFileNodeBatch = createAction<LockFileNodeBatchPayload, "file-locks/lockFileNodeBatch">("file-locks/lockFileNodeBatch");
export const unlockFileNodeBatch = createAction<UnlockFileNodeBatchPayload, "file-locks/unlockFileNodeBatch">("file-locks/unlockFileNodeBatch");
