import {FileParentData} from '@app-features/file-tree/lib/placeholders';
import {getFileThumbnail} from '@app-features/file-tree/utils/fileUtils';
import {appendFileExtension} from '@app-lib/files/nameUtils';
import {LocalFile} from '@app-system/camera/types';
import {startUpload} from './actions';


export function getUploadName(
  parentData: FileParentData,
  file: File | LocalFile,
): string {
  switch (parentData.parentType) {
    case 'FileContainer':
      if (parentData.renameFile && parentData.containerName) {
        return appendFileExtension(file.name, parentData.containerName);
      }
    /* fall-through */
    case 'Folder':
    default:
      return file.name;
  }
}

export function isUploadRetryable(uploadRequest): boolean {
  const stringCode = uploadRequest?.code;
  if (!stringCode) {
    return true;
  }
  const code = Number.parseInt(stringCode, 10);
  // Treat 400-409 as unrecoverable errors.
  return !Number.isFinite(code) || code < 400 || code > 409;
}

export async function loadThumbnail({file}: ReturnType<typeof startUpload>["payload"]) {
  if (('dataUri' in file) && file.dataUri) {
    return file.dataUri;
  }

  try {
    return await getFileThumbnail(file);
  } catch (e) {
  }

  return null;
}
